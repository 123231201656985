import React, {useEffect, useState} from 'react'
import TitleSection from "../../../../../TitleSection";
import {CampaignContentItem} from "./form/CampaignContentItem";
import {useDispatch, useSelector} from "react-redux";
import {generateMongoObjectId} from "../../../../../../utils/generateId";
import {
    addContentToCampaign,
    removeContentItemFromCampaign,
    setContentFieldValue
} from "../../../../../../redux/slice/proposal-system";

export const SelectContent = () => {
    const dispatch = useDispatch();
    const campaignContent = useSelector((state) => state.proposalSystem.campaignContent);
    const [newItemIds, setNewItemIds] = useState([]);
    console.log("campaignContent", campaignContent);
    const addNewContentItemFunc = () => {
        const newId = generateMongoObjectId();
        dispatch(
            addContentToCampaign({
                _id: newId,
                videoLink: "",
                postDescription: "",
                storyTag: "",
                swipeUpLink: "",
                specialWishes: "",
            })
        );
        setNewItemIds((prev) => [...prev, newId]); 
    };

    const removeContentItemFunc = (contentId) => {
        dispatch(removeContentItemFromCampaign(contentId));
        setNewItemIds((prev) => prev.filter((id) => id !== contentId));
    };

    const getContentFieldValueFunc = (contentId, fieldName) => {
        const content = campaignContent.find((content) => content._id === contentId);
        return content[fieldName];
    };

    const setContentFieldValueFunc = (contentId, fieldName, value) => {
        dispatch(setContentFieldValue({ contentId, fieldName, value }));
    };

    return (
        <section className="proposal-system-component">
            <div className="account-client-title">
                <TitleSection title={"Create"} span={"Content"} />
            </div>

            {campaignContent.map((content, index) => (
                <CampaignContentItem
                    key={content._id}
                    numOfContent={index + 1}
                    contentItemId={content._id}
                    getContentFieldValue={getContentFieldValueFunc}
                    setContentFieldValue={setContentFieldValueFunc}
                    showAddButton={index === campaignContent.length - 1}
                    addContentItem={addNewContentItemFunc}
                    showRemoveButton={index !== 0}
                    removeContentItem={removeContentItemFunc}
                    fadeIn={newItemIds.includes(content._id)}
                />
            ))}
        </section>
    );
};
