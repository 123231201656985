import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    balance: 0,
    email: "",
    firstName: "",
    phone: "",
    statusVerify: "",
    instagram: [],
    tiktok: [],
    spotify: [],
    soundcloud: [],
    facebook: [],
    youtube: [],
    press: [],
    currentWindow: 0,
    selectedAccount: null,
    isModified: false,    
}

export const adminEditInfluencerSlice = createSlice({
    name: "admin-edit-influencer",
    initialState,
    reducers: {
        setAdminEditInfluencer: (state, action) => {
            state.balance = action.payload.balance;
            state.email = action.payload.email;
            state.firstName = action.payload.firstName;
            state.phone = action.payload.phone;
            state.statusVerify = action.payload.statusVerify;
            state.instagram = action.payload.instagram;
            state.tiktok = action.payload.tiktok;
            state.spotify = action.payload.spotify;
            state.soundcloud = action.payload.soundcloud;
            state.facebook = action.payload.facebook;
            state.youtube = action.payload.youtube;
            state.press = action.payload.press;
        },
        setPersonalInfo: (state, action) => {
            state[action.payload.key] = action.payload.value;
            state.isModified = true;
        },
        setAdminEditInfluencerClear: (state) => {
            state.balance = 0;
            state.email = "";
            state.firstName = "";
            state.phone = "";
            state.statusVerify = "";
            state.instagram = [];
            state.tiktok = [];
            state.spotify = [];
            state.soundcloud = [];
            state.facebook = [];
            state.youtube = [];
            state.press = [];
            state.currentWindow = 0;
            state.selectedAccount = null;
            state.isModified = false;
        },
        setCurrentWindow: (state, action) => {
            state.currentWindow = action.payload;
        },
        setSelectedAccount: (state, action) => {
            state.selectedAccount = action.payload;
        },
        setSelectedAccountClear: (state) => {
            state.selectedAccount = null;
        },
        deleteSocialMediaAccount: (state, action) => {
            state[action.payload.socialMedia] = state[action.payload.socialMedia].filter((acc) => acc._id !== action.payload._id);
        },
        updateAccount: (state, action) => {
            let socialMediaAccounts = state[action.payload.socialMedia];

            if (!socialMediaAccounts) {
                state[action.payload.socialMedia] = [action.payload.account];
                return;
            }

            let accountUpdated = false;

            socialMediaAccounts = socialMediaAccounts.map((acc) => {
                if (acc._id === action.payload.account._id) {
                    accountUpdated = true;
                    return action.payload.account;
                }
                return acc;
            });

            if (!accountUpdated) {
                socialMediaAccounts.push(action.payload.account);
            }

            state[action.payload.socialMedia] = socialMediaAccounts;
            state.isModified = true;
        },
    },
});

export const {
    setAdminEditInfluencer,
    setSelectedAccount,
    setPersonalInfo,
    setCurrentWindow,
    setAdminEditInfluencerClear,
    setSelectedAccountClear,
    deleteSocialMediaAccount,
    updateAccount,
} = adminEditInfluencerSlice.actions;

export default adminEditInfluencerSlice.reducer;