import React from "react";
import "./searchbarresult.css";

const SearchBarResult = ({result, onResultSelect, typeOfSearch}) => {
    const handleClick = () => {
        onResultSelect(result);
    };

    const showResult = () => {
        switch (typeOfSearch) {
            case "IdForInvoices":
                return result.companyName + "   " + result.amount + "€";
            case "influencers":
                if (result.account !== undefined) {
                    return result.account.instagramUsername;
                } else {
                    return result.instagramUsername;
                }
            case "campaigns":
                return result.campaignName;
            case "influencersHistory":
                return result.networkUsername;
            default:
                break;
        }
    };

    return (
        <div
            className="search-result"
            onClick={handleClick}
        >
            {showResult()}
        </div>
    );
};

export default SearchBarResult;
