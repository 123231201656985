import React from "react";
import Header from "../../../components/Header";
import Background from "../../../components/Background";
import {
    AdminCreateCampaignContainer
} from "../../../components/layout/account/admin/AdminProposalSystem/AdminCreateCampaignContainer";


const AccountAdminProposalSystemManageCampaign = () => {
    const location = window.location.href;
    
    if (location.includes("admin/proposal-system/create-campaign")) {
        return (
            <div>
                <Header path="Admin Panel / Proposal System / Create Campaign"/>
                <AdminCreateCampaignContainer/>
                <Background/>
            </div>
        );
    } else if (location.includes("admin/proposal-system/manage-campaign")) {
        return (
            <div>
                <Header path="Admin Panel / Proposal System / Manage Campaign"/>
                <p>ssssss</p>
                <Background/>
            </div>
        )
    }
};

export default AccountAdminProposalSystemManageCampaign;
