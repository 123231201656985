import React, {useEffect} from 'react'
import {useSelector} from "react-redux";
import TitleSection from "../../../../../TitleSection";
import {AdminEditInfluencerPersonalDetails} from "./AdminEditInfluencerPersonalDetails";
import {AdminEditInfluencerApprovedAccounts} from "./AdminEditInfluencerApprovedAccounts";
import {AdminEditInfluencerAddNewAccounts} from "./AdminEditInfluencerAddNewAccounts";
import StandardButton from "../../../../../form/StandardButton";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {useParams} from "react-router-dom";

export const AdminEditInfluencerMainPage = () => {
    const influencer = useSelector((state) => state.adminEditInfluencer);
    const isModified = useSelector((state) => state.adminEditInfluencer.isModified);
    const id = useParams().influencerId;
    
    useEffect(() => {console.log(influencer)}, [influencer]);
    
    const saveChanges = async () => {
        try {
            const result = await axios.put(`${process.env.REACT_APP_SERVER}/admin/edit-influencer/updateInfluencerAccount`, {
                _id: id,
                email: influencer.email,
                firstName: influencer.firstName,
                phone: influencer.phone,
                instagram: influencer.instagram,
                tiktok: influencer.tiktok,
                spotify: influencer.spotify,
                soundcloud: influencer.soundcloud,
                facebook: influencer.facebook,
                youtube: influencer.youtube,
                press: influencer.press,
            })  
            
            if (result.data.status === 200) {
                window.location.reload();
            } else {
                console.log(result.data.message);
                NotificationManager.error(result.data.message, 'Error', 2000);
            }
        } catch (e) {
            console.log(e);
        }
    }
    
    return (
        <section className="account-influencer-details">
            <div className="container-form">
                <div
                    className="account-influencer-details-block"
                    style={{position: "relative"}}
                >
                    <div className="account-client-title-section">
                        <TitleSection title={influencer.email + "'s"} span="account"/>
                    </div>

                    <p className="account-influencer-details-second">
                        Account Details
                    </p>

                    <div className="account-influencer-details-thoomb">
                        <AdminEditInfluencerPersonalDetails/>
                        <AdminEditInfluencerApprovedAccounts/>
                        <AdminEditInfluencerAddNewAccounts/>
                    </div>

                    {isModified && (
                        <StandardButton isBlue={true} onClick={() => saveChanges()} text="Save Changes" style={{margin: '40px auto 0'}}/>
                    )}
                </div>
            </div>
        </section>
    )
}