import React, {useState} from 'react'
import {generateMongoObjectId} from "../../../../../../utils/generateId";
import instaIcon from "../../../../../../images/icons/socialMedias/instagram.png";
import tikTokIcon from "../../../../../../images/icons/socialMedias/tiktok.png";
import spotifyIcon from "../../../../../../images/icons/socialMedias/spotify.png";
import soundCloudIcon from "../../../../../../images/icons/socialMedias/soundcloud.png";
import facebookIcon from "../../../../../../images/icons/socialMedias/facebook.png";
import youtubeIcon from "../../../../../../images/icons/socialMedias/youtube.png";
import pressIcon from "../../../../../../images/icons/socialMedias/tablet.png";
import {getSocialMedia, getSocialMediaIcon, socialMediaKeys} from "../../../../../../utils/typeOfSocialAccounts";
import StandardButton from "../../../../../form/StandardButton";
import ModalWindow from "../../../../../ModalWindow";
import {setCurrentWindow, setSelectedAccount} from "../../../../../../redux/slice/admin-edit-influencer";
import {setNewSocialMediaAccount} from "../../../../../../redux/slice/signup-influencer";
import {useDispatch, useSelector} from "react-redux";

export const AdminEditInfluencerAddNewAccounts = () => {
    const influencer = useSelector((state) => state.adminEditInfluencer);
    const dispatch = useDispatch();
    
    const [isAccountsReady, setIsAccountsReady] = useState(false);
    const [errorSocialMedia, setErrorSocialMedia] = useState({errorAfterAdding: []});
    const [isSocialAccountsModalOpen, setIsSocialAccountsModalOpen] = useState(false);
    const [selectedSocialMediaForNewAccountsModal, setSelectedSocialMediaForNewAccountsModal] = useState(null);
    
    const createNewAccount = (socialMedia) => {
        const id = generateMongoObjectId();
        const newAccount = {
            instagramUsername: "",
            instagramLink: "",
            followersNumber: "",
            logo: "",
            price: "",
            publicPrice: "",
            socialMedia: socialMedia,
            musicStyle: "",
            musicSubStyles: [],
            musicStyleOther: [],
            _id: id,
            isNew: true,
        }
        dispatch(setSelectedAccount(newAccount));
        dispatch(setCurrentWindow(1));
    };
    
    const openSavedAccount = (account) => {
        dispatch(setSelectedAccount(account));
        dispatch(setCurrentWindow(1));
    }
    
    return (
        <div className="account-influencer-details-wrapper">
            <div className="account-influencer-details-wrapper-header">
                <p className="account-influencer-details-wrapper-header-title">
                    ADD NEW BRAND ACCOUNTS
                </p>
            </div>

            <div className="select-social-media">
                <ul className="social-medias-container">
                    <li onClick={() => {
                        createNewAccount('Instagram')
                    }}>
                        <img src={instaIcon} alt="Instagram"/>
                        <span>Instagram</span>
                    </li>
                    <li onClick={() => {
                        createNewAccount('TikTok')
                    }}>
                        <img src={tikTokIcon} alt="tikTok"/>
                        <span>TikTok</span>
                    </li>
                    <li onClick={() => {
                        createNewAccount('Spotify')
                    }}>
                        <img src={spotifyIcon} alt="spotify"/>
                        <span>Spotify</span>
                    </li>
                    <li onClick={() => {
                        createNewAccount('SoundCloud')
                    }}>
                        <img src={soundCloudIcon} alt="SoundCloud"/>
                        <span>SoundCloud</span>
                    </li>
                    <li onClick={() => {
                        createNewAccount('facebook')
                    }}>
                        <img src={facebookIcon} alt="Facebook"/>
                        <span>Facebook</span>
                    </li>
                    <li onClick={() => {
                        createNewAccount('YouTube')
                    }}>
                        <img src={youtubeIcon} alt="YouTube"/>
                        <span>YouTube</span>
                    </li>
                    <li onClick={() => {
                        createNewAccount('Press')
                    }}>
                        <img style={{marginBottom: 9}} src={pressIcon} alt="Press"/>
                        <span>Press</span>
                    </li>
                    {socialMediaKeys
                        .filter((key) => Array.isArray(influencer[key]))
                        .map((type) => {
                            const newAccounts = influencer[type].filter((account) => account.isNew);
                            const count = newAccounts.length;

                            return count > 0 ? (
                                <div
                                    className="ready-account"
                                    key={type}
                                    onClick={() => {
                                        setSelectedSocialMediaForNewAccountsModal(type);
                                        setIsSocialAccountsModalOpen(true);
                                    }}
                                >
                                    <span className="length">{count}</span>
                                    <li>
                                        <img src={getSocialMediaIcon(type)} alt={type} />
                                        <span>{getSocialMedia(type)}</span>
                                    </li>
                                </div>
                            ) : null;
                        })}
                </ul>
            </div>
            {isAccountsReady && (
                <StandardButton
                    style={{margin: '0 auto 33px auto', width: 210}}
                    text="Save"/>
            )}
            {errorSocialMedia.errorAfterAdding.length > 0 && (
                <div className="error-after-adding">
                    {errorSocialMedia.errorAfterAdding.map((error, index) => (
                        <p key={index}>{error}</p>
                    ))}
                </div>
            )}

            <ModalWindow isOpen={isSocialAccountsModalOpen}
                         setClose={() => setIsSocialAccountsModalOpen(false)}>
                <div className="accounts-list-modal">
                    {influencer[selectedSocialMediaForNewAccountsModal] && influencer[selectedSocialMediaForNewAccountsModal].map((account, index) => {
                        if (account.isNew) {
                            return (
                                <div className="account-block"
                                     onClick={() => openSavedAccount(account)}
                                >
                                    <img src={getSocialMediaIcon(account.socialMedia)}
                                         alt={account.typeOfSocialMedia}/>
                                    <p>{account.instagramUsername ? account.instagramUsername : 'Account ' + ++index}</p>
                                </div>
                            )
                        }
                    })}
                </div>
            </ModalWindow>
        </div>
    )
}
