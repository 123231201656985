import React, {useEffect, useState} from 'react'
import TitleSection from "../../../../../TitleSection";
import Loading from "../../../../../form/PageLoading/pageLoading";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedClientId} from "../../../../../../redux/slice/proposal-system";
import {NotificationManager} from "react-notifications";

export const SelectClient = ({clients}) => {
    const dispatch = useDispatch();
    const selectedClientId = useSelector(state => state.proposalSystem.selectedClientId)

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!clients || !clients.length > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [clients]);

    const selectClientForCampaign = (clientId) => {
        try {
            if (clientId === selectedClientId) {
                dispatch(setSelectedClientId(""));
            } else {
                dispatch(setSelectedClientId(clientId));
            }
        } catch (err) {
            NotificationManager.error("Error", "Error selecting client", 3000)
        }
    };
    
    if (isLoading) return <Loading/>;
    return (
        <section className="proposal-system-component">
            <div className="account-client-title">
                <TitleSection title={"select"} span={"client"}/>
            </div>

            <div className="proposal-system-clients-table-container">
                <table className="proposal-system-clients-table">
                    <thead className="proposal-system-clients-table-header">
                    <tr>
                        <th>First Name</th>
                        <th>Email</th>
                        <th>Client ID</th>
                    </tr>
                    <tr></tr>
                    </thead>
                    <tbody className="proposal-system-clients-table-body">
                    {clients.map((client, index) => (
                        <tr key={index} className={client._id === selectedClientId ? 'selected-client' : ''} onClick={() => selectClientForCampaign(client._id)}>
                            <td>{client.firstName}</td>
                            <td>{client.email}</td>
                            <td>{client._id}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </section>
    )
}