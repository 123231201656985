import React, {useEffect, useState} from "react";
import backBtn from "../../../../../images/icons/arrow.svg";
import TitleSection from "../../../../TitleSection";
import {useNavigate} from "react-router-dom";
import {getSocialMediaIcon, typeOfAccounts} from "../../../../../utils/typeOfSocialAccounts";
import Loading from "../../../../form/PageLoading/pageLoading";
import axios from "axios";
import {NotificationContainer, NotificationManager} from "react-notifications";
import SearchBarComponent from "../../../../form/SearchBar/SearchBar";
import AdminInfluencerHistory from "./AdminInfluencerHistory";

const AdminInfluencersHistoryPage = () => {
    const navigate = useNavigate();
    const [selectedSocialMedia, setSelectedSocialMedia] = useState("instagram");
    const [influencers, setInfluencers] = useState([]);
    const [influencerHistorySearchResult, setInfluencerHistorySearchResult] = useState(null);
    const [influencerHistory, setInfluencerHistory] = useState(null);

    useEffect(() => {
        getAllInfluencersHistories();
    }, []);

    useEffect(() => {
        if (influencerHistorySearchResult) {
            setInfluencerHistory(influencers.find(influencer => influencer.influencerId === influencerHistorySearchResult.influencerId));
        }
    }, [influencerHistorySearchResult]);

    useEffect(() => {
        setInfluencerHistorySearchResult(null);
        setInfluencerHistory(null);
    }, [selectedSocialMedia]);

    const getAllInfluencersHistories = async () => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_SERVER}/influencer-actions-history/get/all`);

            if (result.data.code === 200) {
                setInfluencers(result.data.influencerActionsHistory);
            } else {
                NotificationManager.error(result.data.message, 'Error', 5000);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const searchFunction = (data, searchInput) => {
        return data.filter(account => account.networkUsername.includes(searchInput));
    };

    return (
        <section className="admin">
            <div className="admin-influencers-history">
                <div className="admin-title-section">
                    <button onClick={() => navigate('/admin/home')}>
                        <img src={backBtn} style={{transform: "rotate(180deg)"}}/>
                    </button>
                    <TitleSection title="Influencers" span="History"/>
                </div>
                <div className="admin-influencers-history-search-container">
                    <span>PLATFORMS</span>

                    <div className="social-medias-container">
                        <div className="admin-influencers-platforms-container">
                            {typeOfAccounts.map((socialMediaType, index) => (
                                <li key={index}
                                    className={`admin-influencers-platforms-container-item ${selectedSocialMedia === socialMediaType.db ? 'active' : ''}`}>
                                    <button onClick={() => {
                                        setSelectedSocialMedia(socialMediaType.db)
                                    }}>
                                        <img src={getSocialMediaIcon(socialMediaType.db)} alt={'icon'}
                                             style={{width: 35}}/>
                                        {socialMediaType.publicLink}
                                    </button>
                                </li>
                            ))}
                        </div>
                    </div>

                    {selectedSocialMedia !== "" && selectedSocialMedia ? (
                        <div className="admin-influencers-history-search">
                            <SearchBarComponent
                                data={influencers.flatMap(influencer => {
                                    const usernamesFilteredBySocialMedia = influencer.influencerNetworkUsernames.filter(account => account.socialMedia === selectedSocialMedia);
                                    return usernamesFilteredBySocialMedia;
                                })}
                                setSearchResult={setInfluencerHistorySearchResult}
                                searchFunction={searchFunction}
                                className="large"
                                typeOfSearch="influencersHistory"
                            />
                        </div>
                    ) : (<Loading/>)}
                </div>
                {influencerHistorySearchResult && influencerHistory && (
                    <AdminInfluencerHistory influencerHistory={influencerHistory}/>
                )}
                <NotificationContainer/>
            </div>
        </section>
    );
};

export default AdminInfluencersHistoryPage;