import React, {useState} from 'react'
import ModalWindow from "../../../../../../ModalWindow";
import videoImg from "../../../../../../../images/icons/iconsForCampaignReport/video 1.svg";
import linkImg from "../../../../../../../images/icons/iconsForCampaignReport/link 1.svg";

export const SelectCampaignContentItemModal = ({isOpen, setClose, campaignItems, selectItem}) => {
    const [chosenItem, setChosenItem] = useState(null);
    
    const handleOnClick = (item) => {
        setChosenItem(item);
    }
    
    return (
        <ModalWindow isOpen={isOpen} setClose={setClose}>
            <div className="select-campaign-content-item-modal">
                <div className="title">
                    <p>SELECT CONTENT FOR INFLUENCER</p>
                </div>
                
                <div className="select-container">
                    {campaignItems.map((item, index) => (
                        <div className={`campaign-content-item ${chosenItem?._id === item._id ? "chosen" : ""}`} onClick={() => handleOnClick(item)}>
                            <img src={videoImg} alt="video"/>
                            <span>#{index + 1}</span>
                            <img src={linkImg} alt="link"/>
                        </div>
                    ))}
                </div>
            </div>
        </ModalWindow>
    )
}
