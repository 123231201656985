import React, {useEffect, useState} from "react";
import Header from "../../../components/Header";
import Background from "../../../components/Background";
import {useNavigate, useParams} from "react-router-dom";
import {
    AdminEditInfluencerMainPage
} from "../../../components/layout/account/admin/AdminInfluencersList/AdminEditInfluencer/AdminEditInfluencerMainPage";
import {NotificationContainer, NotificationManager} from "react-notifications";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {setAdminEditInfluencer, setAdminEditInfluencerClear} from "../../../redux/slice/admin-edit-influencer";
import Loading from "../../../components/form/PageLoading/pageLoading";
import {
    AdminEditInfluencerEditPage
} from "../../../components/layout/account/admin/AdminInfluencersList/AdminEditInfluencer/AdminEditInfluencerEditPage";

const AccountAdminEditInfluencer = () => {
    const influencerId = useParams().influencerId;
    const currentWindow = useSelector((state) => state.adminEditInfluencer.currentWindow);
    const dispatch = useDispatch();
    
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        getInfluencerData();

        return () => {
            dispatch(setAdminEditInfluencerClear());
        };
    }, []);
    
    const getInfluencerData = async () => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_SERVER}/admin/edit-influencer/getInfluencerAccount/${influencerId}`);
            
            if (result.data.status === 200) {
                dispatch(setAdminEditInfluencer(result.data.influencer));
                setLoading(false);
            } else {
                NotificationManager.error("Error", "Can't find influencer by this ID", 3000);
            }
        } catch (err) {
            NotificationManager.error("Error", "An error occurred", 3000);
            console.log(err);
        }
    }
    
    return (
        <>
            <Header path={"Admin / Influencer / Edit account / " + influencerId}/>
            {loading ? <Loading/> : currentWindow === 0 ? <AdminEditInfluencerMainPage/> : <AdminEditInfluencerEditPage/>}
            <Background />
            <NotificationContainer />
        </>
    );
};

export default AccountAdminEditInfluencer;
