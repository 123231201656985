import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    removeAccount,
    setIsAccountInfoOpen,
    setIsAddInfluencerToCampaignModalOpen, setIsSelectCampaignContentItemModalOpen
} from "../../../../../../../redux/slice/proposal-system";
import watch from "../../../../../../../images/icons/view 1.svg";
import plusImg from "../../../../../../../images/icons/plus 2.svg";
import editImg from "../../../../../../../images/icons/edit 1.svg";

export const ManageInfluencersInCampaignTable = ({influencers}) => {
    //TODO: Использовать функцию (из typeOfSocialAccounts) которая будет возвращать названия полей для Campaign Details в зависимости от соц сети
    const addedAccounts = useSelector(state => state.proposalSystem.addedAccounts);
    const dispatch = useDispatch();

    const [addedFullAccounts, setAddedFullAccounts] = useState([]);

    useEffect(() => {
        const newAccounts = addedAccounts.filter(account =>
            !addedFullAccounts.some(fullAccount =>
                fullAccount.influencerId === account.influencerId &&
                fullAccount.instagramUsername === account.instagramUsername
            )
        );

        if (newAccounts.length > 0) {
            const fullAccounts = newAccounts
                .map(account => influencers.find(
                    influencer =>
                        influencer.influencerId === account.influencerId &&
                        influencer.instagramUsername === account.instagramUsername
                ))
                .filter(Boolean);

            setAddedFullAccounts(prev => {
                const uniqueAccounts = [...prev, ...fullAccounts].reduce((acc, current) => {
                    const exists = acc.some(
                        item =>
                            item.influencerId === current.influencerId &&
                            item.instagramUsername === current.instagramUsername
                    );
                    if (!exists) acc.push(current);
                    return acc;
                }, []);
                return uniqueAccounts;
            });
        }
    }, [addedAccounts, influencers]);

    // useEffect(() => {
    //     console.log(addedFullAccounts);
    // }, [addedFullAccounts]);

    const handleRemoveAccount = (instagramUsername, influencerId) => {
        dispatch(removeAccount({instagramUsername, influencerId}));
        setAddedFullAccounts(prev => prev.filter(account =>
            account.instagramUsername !== instagramUsername || account.influencerId !== influencerId
        ));
    };

    const calculateTotalFollowers = () => {
        return addedFullAccounts.reduce((acc, current) => acc + parseInt(current.followersNumber), 0);
    };

    const calculateTotalPrice = () => {
        return addedFullAccounts.reduce((acc, current) => acc + parseInt(current.publicPrice), 0);
    };

    return (
        <table className="proposal-system-influencers-table">
            <thead className="proposal-system-influencers-table-header">
            <tr>
                <th>Networks</th>
                <th>Total Followers</th>
                <th>Account Info</th>
                <th>Date Post</th>
                <th>Video</th>
                <th>Post Description</th>
                <th>Story tag</th>
                <th>Story Link</th>
                {/*<th>Genres</th>*/}
                {/*<th>Top 5 Countries</th>*/}
                <th>Special Requests</th>
            </tr>
            </thead>
            <tbody className="proposal-system-influencers-table-body">
            {addedFullAccounts.map((account, index) => (
                <tr key={index}>
                    <td className="logo-username">
                        <img src={account.logo} alt=""/>
                        <p>{account.instagramUsername}</p>
                    </td>
                    <td className="followers-number">{account.followersNumber}</td>
                    <td className="account-info">
                        <div>
                            <button onClick={() => {
                                dispatch(setIsAccountInfoOpen({isOpen: true, account}));
                            }}>
                                <img src={watch} alt=""/>
                            </button>
                        </div>
                    </td>
                    <td></td>
                    <td className="select-content">
                        <div className="container">
                            <div>
                                <button onClick={() => dispatch(setIsSelectCampaignContentItemModalOpen(true))}>
                                    <img src={editImg} alt=""/>
                                </button>
                            </div>
                            <div>
                                <button onClick={() => {
                                    dispatch(setIsAccountInfoOpen({isOpen: true, account}));
                                }}>
                                    <img src={watch} alt=""/>
                                </button>
                            </div>
                        </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    {/*<td>{account.datePost}</td>*/}
                    {/*<td>{account.video}</td>*/}
                    {/*<td>{account.postDescription}</td>*/}
                    {/*<td>{account.storyTag}</td>*/}
                    {/*<td>{account.storyLink}</td>*/}
                    {/*<td>{account.genres}</td>*/}
                    {/*<td>{account.top5Countries}</td>*/}
                    {/*<td>{account.specialRequests}</td>*/}
                </tr>
            ))}
            <tr className="add-new-account-tr">
                <td onClick={() => dispatch(setIsAddInfluencerToCampaignModalOpen(true))}>
                    <div className="add-new-account-tr-plus">
                        <img src={plusImg} alt=""/>
                    </div>
                    <p>Add Influencer</p>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            </tbody>
            <tfoot className="proposal-system-influencers-table-footer">
            <tr>
                <td>PRICE: {calculateTotalPrice()}€</td>
                <td>{calculateTotalFollowers()}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            </tfoot>
        </table>
    )
}
