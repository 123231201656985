import React, {useEffect, useState} from 'react'
import TitleSection from "../../../../../TitleSection";
import Loading from "../../../../../form/PageLoading/pageLoading";
import {useDispatch, useSelector} from "react-redux";
import {setCampaignName} from "../../../../../../redux/slice/proposal-system";
import {NewCampaignDetails} from "../../../../../form/NewCampaignDetails/NewCampaignDetails";
import {ManageInfluencersInCampaignTable} from "./form/ManageInfluencersInCampaignTable";
import ModalWindow from "../../../../../ModalWindow";

export const SelectInfluencers = ({influencers}) => {
    const dispatch = useDispatch();
    
    const [isLoading, setIsLoading] = useState(true);
    
    const campaignName = useSelector(state => state.proposalSystem.campaignName);

    useEffect(() => {
        if (!influencers || !influencers.length > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }

    }, [influencers]);
    
    if (isLoading) return <Loading/>;
    return (
        <section className="proposal-system-component">
            <div className="account-client-title">
                <TitleSection title={"Campaign"} span={"Strategy"}/>
            </div>
            
            <div className="proposal-system-influencers">
                <div className="details">
                    <div className="details-campaign-name">
                        <input 
                            type="text" 
                            placeholder="Campaign Name"    
                            value={campaignName}
                            onChange={(e) => dispatch(setCampaignName(e.target.value))}
                        />
                    </div>
                    
                    <div>
                        {/*<NewCampaignDetails/>*/}
                    </div>
                </div>
                
                <div className="proposal-system-influencers-table-container">
                    <ManageInfluencersInCampaignTable influencers={influencers}/>
                </div>
            </div>
        </section>
    )
}
