import React from "react";
import Header from "../../../components/Header";
import Background from "../../../components/Background";
import {
    AdminManageProposalSystem
} from "../../../components/layout/account/admin/AdminProposalSystem/AdminManageProposalSystem";

const AccountAdminProposalSystem = () => {
    return (
        <div>
            <Header path="Admin Panel / Proposal System" />
            <AdminManageProposalSystem />
            <Background />
        </div>
    );
};

export default AccountAdminProposalSystem;
