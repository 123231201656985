import React, {useEffect, useState} from 'react'
import FormContainer from "../../../../../../form/FormContainer";
import StandardButton from "../../../../../../form/StandardButton";
import TextInput from "../../../../../../form/TextInput";
import TextArea from "../../../../../../form/TextArea";
import deleteImg from "../../../../../../../images/icons/close.svg";

export const CampaignContentItem = ({
                                        numOfContent,
                                        contentItemId,
                                        addContentItem,
                                        getContentFieldValue,
                                        setContentFieldValue,
                                        showAddButton = true,
                                        showRemoveButton = false,
                                        removeContentItem,
                                        fadeIn = false,
                                    }) => {
    const [fadeClass, setFadeClass] = useState("");

    useEffect(() => {
        if (fadeIn) {
            const timeout = setTimeout(() => setFadeClass("fade-in"), 10);
            return () => clearTimeout(timeout);
        }
    }, [fadeIn]);

    return (
        <div className={`content-form-container ${numOfContent === 1 ? '' : fadeClass}`}>
            <div className="title">
                <p>VIDEO {numOfContent}</p>
            </div>

            <FormContainer style={{margin: '40px auto 0', width: '60%'}}>
                <div className="content-form">
                    <div className="remove-button">
                        {showRemoveButton && (
                            <div className="remove-content-button" onClick={() => removeContentItem(contentItemId)}>
                                <img src={deleteImg} alt="delete"/>
                            </div>
                        )}
                    </div>
                    <div>
                        <div className="content-form-item">
                            <TextInput
                                title="Videolink"
                                style={{marginTop: 0}}
                                placeholder="Enter videolink"
                                value={getContentFieldValue(contentItemId, "videoLink")}
                                setValue={(value) => setContentFieldValue(contentItemId, "videoLink", value)}
                                silverColor={true}
                            />
                        </div>
                        <div className="content-form-item">
                            <TextArea
                                title="Post Description"
                                value={getContentFieldValue(contentItemId, "postDescription")}
                                setValue={(value) => setContentFieldValue(contentItemId, "postDescription", value)}
                                style={{marginTop: 0}}
                                placeholder="Enter description"
                            />
                        </div>
                        <div className="content-form-item">
                            <TextInput
                                title="Story Tag"
                                style={{marginTop: 0}}
                                placeholder="Enter story tag"
                                value={getContentFieldValue(contentItemId, "storyTag")}
                                setValue={(value) => setContentFieldValue(contentItemId, "storyTag", value)}
                                silverColor={true}
                            />
                        </div>
                        <div className="content-form-item">
                            <TextInput
                                title="Story Link"
                                style={{marginTop: 0}}
                                placeholder="Enter story link"
                                value={getContentFieldValue(contentItemId, "swipeUpLink")}
                                setValue={(value) => setContentFieldValue(contentItemId, "swipeUpLink", value)}
                                silverColor={true}
                            />
                        </div>
                        <div className="content-form-item">
                            <TextArea
                                title="Special Requests"
                                value={getContentFieldValue(contentItemId, "specialWishes")}
                                setValue={(value) => setContentFieldValue(contentItemId, "specialWishes", value)}
                                style={{marginTop: 0}}
                                placeholder="Enter special requests"
                            />
                        </div>
                    </div>
                </div>
            </FormContainer>

            {showAddButton && (
                <div className="add-additional-content-button">
                    <StandardButton isBlue={true} onClick={addContentItem} text="Add Additional Video"/>
                </div>
            )}
        </div>
    );
};