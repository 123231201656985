import React from 'react'
import {getSocialMedia, getSocialMediaIcon, socialMediaKeys} from "../../../utils/typeOfSocialAccounts";
import "./selectSocialMediaHorizontalList.css";

export const SelectSocialMediaHorizontalList = ({selectSocialMedia, selectedSocialMedia}) => {
    return (
        <div className="social-media-horizontal-list">
            {socialMediaKeys.map((socialMedia, index) => (
                <div key={index} className={`social-media-horizontal-list-item ${selectedSocialMedia === socialMedia ? "selected" : ""}`} onClick={() => selectSocialMedia(socialMedia)}>
                    <img src={getSocialMediaIcon(socialMedia)}/>
                    <p>{getSocialMedia(socialMedia)}</p>
                </div>
            ))}
        </div>
    )
}
