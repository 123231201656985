import React from 'react'
import ModalWindow from "../../../../../../ModalWindow";
import {useDispatch, useSelector} from "react-redux";
import {setIsAccountInfoOpen} from "../../../../../../../redux/slice/proposal-system";
import {returnMusicStyles} from "../../../../../../../utils/musicStyles";

export const AccountModalInfo = () => {
    const isAccountInfoOpen = useSelector(state => state.proposalSystem.isAccountInfoOpen);
    const dispatch = useDispatch();

    const setClose = () => {
        dispatch(setIsAccountInfoOpen(false, {}));
    }

    return (
        <ModalWindow isOpen={isAccountInfoOpen.isOpen} setClose={setClose}>
            <div className="proposal-system-influencers-account-info-modal">
                <div className="block">
                    <div className="title">
                        <p>GENRES</p>
                    </div>
                    <div className="content">
                        {returnMusicStyles(
                            isAccountInfoOpen.account.musicStyle,
                            isAccountInfoOpen.account.musicStyleOther,
                            isAccountInfoOpen.account.musicSubStyles)
                            .map((genre, index) => (
                                <div className="genres-container">
                                    <p>{genre}</p>
                                </div>
                            ))}
                    </div>
                </div>
                <div className="block">
                    <div className="title">
                        <p>TOP 5 COUNTRIES</p>
                    </div>
                    <div className="content">
                        {isAccountInfoOpen.account.countries.map((countryObj, index) =>
                            <div className="countries-container">
                                <p>{countryObj.country}</p>
                                <p>{countryObj.percentage}%</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ModalWindow>
    )
}
