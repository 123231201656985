import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import close from "../../images/icons/close.svg";

const ModalWindow = ({ children, isOpen = false, setClose, header = "" }) => {
    const [isAnimating, setIsAnimating] = useState(false);

    const changeStateModal = () => {
        setClose(!isOpen);
    };

    useEffect(() => {
        if (isOpen) {
            setIsAnimating(true);
        } else {
            setTimeout(() => setIsAnimating(false), 250);
        }
    }, [isOpen]);

    return (
        <>
            {isOpen && (
                <div
                    data-head="backdrop"
                    className={`${styles.backdrop} ${isOpen ? styles.open : styles.closed}`}
                >
                    <div className={styles.modal}>
                        <button onClick={changeStateModal} className={styles.close}>
                            <img src={close} alt="Close"/>
                        </button>
                        {header && (
                            <div className={styles.modalHeader}>
                                <p className={styles.modalHeaderTitle}>{header}</p>
                            </div>
                        )}
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};

export default ModalWindow;
