import React, {useEffect, useState} from "react";
import TextInput from "../../../../form/TextInput";
import StandardButton from "../../../../form/StandardButton";
import {
    setAccountNumber,
    setAmount,
    setBankAccountCurrency,
    setBankBranchName,
    setBankCountry,
    setBankName,
    setBeneficiary,
    setBeneficiaryAddress,
    setCurrentWindow,
    setIban,
    setPayee,
    setSortCode,
    setSwiftOrBic,
    setClearForm,
    setPaypalEmail,
    setClearFormWithoutAmount,
    setSelectPaymentMethod,
} from "../../../../../redux/slice/create-invoice";
import {useDispatch, useSelector} from "react-redux";

const CreateInvoicePayment = () => {
        const dispatch = useDispatch();
        const dataForm = useSelector((state) => state.createInvoice.data);
        const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("UK BANK TRANSFER");
        const [errorFormPayment, setErrorFormPayment] = useState({
            paypalEmail: false,
            bankName: false,
            bankCountry: false,
            bankAccountCurrency: false,
            sortCode: false,
            accountNumber: false,
            swiftOrBic: false,
            amount: false,
            iban: false,
        });

        const nextForm = () => {
            let listError = {
                paypalEmail: false,
                bankName: false,
                beneficiary: false,
                beneficiaryAddress: false,
                bankCountry: false,
                bankAccountCurrency: false,
                sortCode: false,
                accountNumber: false,
                swiftOrBic: false,
                amount: false,
                iban: false,
            };

            let haveError = false;
            for (let checkError in listError) {
                if (selectedPaymentMethod === "Paypal") {
                    if (checkError === "bankName" || checkError === "bankCountry" || checkError === "bankAccountCurrency" || checkError === "swiftOrBic" || checkError === "iban" || checkError === "sortCode" || checkError === "accountNumber" || checkError === "beneficiary" || checkError === "beneficiaryAddress") {
                        if (!dataForm[checkError]) {
                            continue;
                        }
                    }
                } else if (selectedPaymentMethod === "UK BANK TRANSFER") {
                    if (checkError === "bankName" || checkError === "bankCountry" || checkError === "bankAccountCurrency" || checkError === "swiftOrBic" || checkError === "iban" || checkError === "paypalEmail") {
                        if (!dataForm[checkError]) {
                            continue;
                        }
                    }
                } else if (selectedPaymentMethod === "International BANK TRANSFER") {
                    if (checkError === "sortCode" || checkError === "accountNumber" || checkError === "paypalEmail") {
                        if (!dataForm[checkError]) {
                            continue;
                        }
                    }
                }

                if (!dataForm[checkError]) {
                    listError[checkError] = true;
                    haveError = true;
                }
            }

            if (haveError) return setErrorFormPayment(listError);

            dispatch(setSelectPaymentMethod(selectedPaymentMethod));
            dispatch(setCurrentWindow(1));
        };

        const handleAmount = (value) => {
            const balance = window.sessionStorage.getItem("balance");
            if (+balance >= +value) return dispatch(setAmount(value));
        };

        useEffect(() => {
            if (selectedPaymentMethod === "Paypal") dispatch(setClearFormWithoutAmount());
        }, [selectedPaymentMethod]);

        useEffect(() => {
            setErrorFormPayment({
                // payee: false,
                paypalEmail: false,
                bankName: false,
                // bankBranchName: false,
                bankCountry: false,
                bankAccountCurrency: false,
                sortCode: false,
                accountNumber: false,
                swiftOrBic: false,
                amount: false,
            })
        }, [selectedPaymentMethod]);

        return (
            <>
                <div className="container-form">
                    <p className="create-invoice-second">Select Payment Method</p>
                    <div className="create-invoice-select">
                        <button
                            className={`create-invoice-select-button ${
                                selectedPaymentMethod === "UK BANK TRANSFER" && "active"
                            }`}
                            onClick={() => {
                                setSelectedPaymentMethod("UK BANK TRANSFER")
                            }}
                        >
                            UK BANK TRANSFER
                        </button>
                        <button
                            className={`create-invoice-select-button ${
                                selectedPaymentMethod === "International BANK TRANSFER" && "active"
                            }`}
                            onClick={() => {
                                setSelectedPaymentMethod("International BANK TRANSFER")
                            }}
                        >
                            International BANK TRANSFER
                        </button>
                        <button
                            className={`create-invoice-select-button ${
                                selectedPaymentMethod === "Paypal" && "active"
                            }`}
                            onClick={() => {
                                dispatch(setClearForm());
                                setSelectedPaymentMethod("Paypal");
                            }}
                        >
                            Paypal
                        </button>
                    </div>
                    <div className="create-invoice-form">
                        <div className="create-invoice-form-header">
                            <p className="create-invoice-form-header-title">Bank Transfer</p>
                        </div>

                        <div className="create-invoice-form-content">
                            {/* <TextInput
              title="Payee*"
              placeholder="Enter Payee"
              style={{ maxWidth: "665px", margin: "0 auto" }}
              value={dataForm.payee}
              setValue={(value) => dispatch(setPayee(value))}
              error={errorForm.payee}
              onFocus={() => setErrorForm({ ...errorForm, payee: false })}
            /> */}
                            {selectedPaymentMethod !== "Paypal" && (
                                <>
                                    <TextInput
                                        title="Beneficiary*"
                                        placeholder="Enter Beneficiary"
                                        style={{
                                            maxWidth: "665px",
                                            margin: "0 auto",
                                            marginTop: "60px",
                                        }}
                                        value={dataForm.beneficiary}
                                        setValue={(value) => dispatch(setBeneficiary(value))}
                                        error={errorFormPayment.beneficiary}
                                        onFocus={() =>
                                            setErrorFormPayment({...errorFormPayment, beneficiary: false})
                                        }
                                        autoComplete="off"
                                    />
                                    <TextInput
                                        title="Beneficiary address*"
                                        placeholder="Enter Beneficiary address"
                                        style={{
                                            maxWidth: "665px",
                                            margin: "0 auto",
                                            marginTop: "60px",
                                        }}
                                        value={dataForm.beneficiaryAddress}
                                        setValue={(value) => dispatch(setBeneficiaryAddress(value))}
                                        error={errorFormPayment.beneficiaryAddress}
                                        onFocus={() =>
                                            setErrorFormPayment({...errorFormPayment, beneficiaryAddress: false})
                                        }
                                        autoComplete="new-password"
                                    />
                                </>
                            )}
                            {selectedPaymentMethod === "International BANK TRANSFER" ? (
                                <>
                                    {" "}
                                    <TextInput
                                        title="IBAN*"
                                        placeholder="Enter IBAN"
                                        style={{
                                            maxWidth: "665px",
                                            margin: "0 auto",
                                            marginTop: "60px",
                                        }}
                                        value={dataForm.iban}
                                        setValue={(value) => dispatch(setIban(value))}
                                        error={errorFormPayment.iban}
                                        onFocus={() => setErrorFormPayment({...errorFormPayment, iban: false})}
                                        autoComplete="off"
                                    />
                                    <TextInput
                                        title="Bank Name*"
                                        placeholder="Enter Bank Name"
                                        style={{
                                            maxWidth: "665px",
                                            margin: "0 auto",
                                            marginTop: "60px",
                                        }}
                                        value={dataForm.bankName}
                                        setValue={(value) => dispatch(setBankName(value))}
                                        error={errorFormPayment.bankName}
                                        onFocus={() =>
                                            setErrorFormPayment({...errorFormPayment, bankName: false})
                                        }
                                    />
                                    <TextInput
                                        title="Bank Country*"
                                        placeholder="Enter Bank Country"
                                        style={{
                                            maxWidth: "665px",
                                            margin: "0 auto",
                                            marginTop: "60px",
                                        }}
                                        value={dataForm.bankCountry}
                                        setValue={(value) => dispatch(setBankCountry(value))}
                                        error={errorFormPayment.bankCountry}
                                        onFocus={() =>
                                            setErrorFormPayment({...errorFormPayment, bankCountry: false})
                                        }
                                        autoComplete="new-password"
                                    />
                                    <TextInput
                                        title="Bank Account Currency*"
                                        placeholder="Enter Bank Account Currency"
                                        style={{
                                            maxWidth: "665px",
                                            margin: "0 auto",
                                            marginTop: "60px",
                                        }}
                                        value={dataForm.bankAccountCurrency}
                                        setValue={(value) => dispatch(setBankAccountCurrency(value))}
                                        error={errorFormPayment.bankAccountCurrency}
                                        onFocus={() =>
                                            setErrorFormPayment({...errorFormPayment, bankAccountCurrency: false})
                                        }
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                            {selectedPaymentMethod === "UK BANK TRANSFER" ? (
                                <>
                                    <TextInput
                                        title="Sort Code*"
                                        placeholder="Enter Sort Code"
                                        style={{
                                            maxWidth: "665px",
                                            margin: "0 auto",
                                            marginTop: "60px",
                                        }}
                                        value={dataForm.sortCode}
                                        setValue={(value) => dispatch(setSortCode(value))}
                                        error={errorFormPayment.sortCode}
                                        onFocus={() =>
                                            setErrorFormPayment({...errorFormPayment, sortCode: false})
                                        }
                                        autoComplete={"off"}
                                    />
                                    <TextInput
                                        title="Account Number*"
                                        placeholder="Enter Account Number"
                                        style={{
                                            maxWidth: "665px",
                                            margin: "0 auto",
                                            marginTop: "60px",
                                        }}
                                        value={dataForm.accountNumber}
                                        setValue={(value) => dispatch(setAccountNumber(value))}
                                        error={errorFormPayment.accountNumber}
                                        onFocus={() =>
                                            setErrorFormPayment({...errorFormPayment, accountNumber: false})
                                        }
                                        allowAutoComplete={false}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                            {selectedPaymentMethod === "International BANK TRANSFER" ? (
                                <>
                                    <TextInput
                                        title="SWIFT / BIC"
                                        placeholder="SWIFT / BIC"
                                        style={{
                                            maxWidth: "665px",
                                            margin: "0 auto",
                                            marginTop: "60px",
                                        }}
                                        value={dataForm.swiftOrBic}
                                        setValue={(value) => dispatch(setSwiftOrBic(value))}
                                        error={errorFormPayment.swiftOrBic}
                                        onFocus={() =>
                                            setErrorFormPayment({...errorFormPayment, swiftOrBic: false})
                                        }
                                        allowAutoComplete={false}
                                    />
                                </>
                            ) : (
                                <></>
                            )}

                            {selectedPaymentMethod === "Paypal" && <TextInput
                                title="Paypal email"
                                placeholder="Enter paypal email"
                                style={{maxWidth: "665px", margin: "0 auto", marginTop: "60px"}}
                                value={dataForm.paypalEmail}
                                setValue={(value) => dispatch(setPaypalEmail(value))}
                                autoComplete="off"
                                error={errorFormPayment.paypalEmail}
                                // onFocus={() => setErrorForm({ ...errorForm, amount: false })}
                            />
                            }

                            <TextInput
                                title="Amount"
                                placeholder="Enter Amount"
                                style={{maxWidth: "665px", margin: "0 auto", marginTop: "60px"}}
                                value={dataForm.amount}
                                setValue={handleAmount}
                                error={errorFormPayment.amount}
                                onFocus={() => setErrorFormPayment({...errorFormPayment, amount: false})}
                                allowAutoComplete={false}
                            />


                            {selectedPaymentMethod !== "Paypal" && (
                                <div className="create-invoice-form-content-notification">
                                    <p className="create-invoice-form-content-notification-text">
                                        Please note that the required numbers for receiving money
                                        through bank transfers can vary depending on your country. If
                                        you are uncertain about the specific requirements, we
                                        recommend contacting your bank directly for information
                                        regarding international payments.
                                    </p>
                                </div>
                            )}

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "60px",
                                    marginBottom: window.innerWidth > 550 ? "0" : "40px",
                                }}
                            >
                                <StandardButton
                                    text={selectedPaymentMethod === "Paypal" ? "Continue with Paypal" : "Continue with Bank"}
                                    onClick={nextForm}
                                    style={{padding: "6px 25px"}}
                                />
                            </div>
                        </div>
                    </div>

                    {/* <div className="create-invoice-form">
          <div className="create-invoice-form-header">
            <p className="create-invoice-form-header-title">Paypal</p>
          </div>

          <div
            className="create-invoice-form-content"
            style={{ padding: "50px 34px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <AltButton text="Connect new PayPal account" />
            </div>

            <div className="create-invoice-form-content-notification">
              <p className="create-invoice-form-content-notification-text">
                <strong>Bank details are required in all instances.</strong>
                <br />
                PayPal details should only be provided in the event you do not
                have a bank account.
                <br />
                <br /> You may be charged fees (up to 6%) by PayPal and in some
                cases money can be held by them before being paid though to you.
                We cannot be held responsible for money held or lost by PayPal
                and we will only be able to re-pay funds if they have been
                returned to us in full.
              </p>
            </div>
          </div>
        </div> */}
                </div>
            </>
        );
    }
;

export default CreateInvoicePayment;
