import {createSlice} from '@reduxjs/toolkit';
import {generateMongoObjectId} from "../../utils/generateId";

const saveStateToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("proposalSystemState", serializedState);
    } catch (e) {
        console.error("Error saving state to localStorage:", e);
    }
};

const loadStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem("proposalSystemState");
        // console.log("serializedState", serializedState);
        return serializedState ? JSON.parse(serializedState) : {
            currentWindowIndex: 0,
            selectedSocialMedia: "",
            selectedClientId: "",
            campaignName: "",
            addedAccounts: [{influencerId: "65992c6a53764b2ccda0756e", instagramUsername: "Techno Germany"}],
            campaignContent: [{
                _id: generateMongoObjectId(),
                videoLink: "",
                postDescription: "",
                storyTag: "",
                swipeUpLink: "",
                specialWishes: ""
            }],
            isAccountInfoOpen: {isOpen: false, account: {}},
            isAddInfluencerToCampaignModalOpen: false,
            isSelectCampaignContentItemModalOpen: false,
        };
    } catch (e) {
        console.error("Error loading state from localStorage:", e);
        return {
            currentWindowIndex: 0,
            selectedSocialMedia: "",
            selectedClientId: "",
            campaignName: "",
            addedAccounts: [{influencerId: "65992c6a53764b2ccda0756e", instagramUsername: "Techno Germany"}],
            campaignContent: [{
                _id: generateMongoObjectId(),
                videoLink: "",
                postDescription: "",
                storyTag: "",
                swipeUpLink: "",
                specialWishes: ""
            }],
            isAccountInfoOpen: {isOpen: false, account: {}},
            isAddInfluencerToCampaignModalOpen: false,
            isSelectCampaignContentItemModalOpen: false,
        };
    }
};

const initialState = loadStateFromLocalStorage();

export const proposalSystemSlice = createSlice({
    name: "proposal-system",
    initialState,
    reducers: {
        clearState: (state) => {
            state.currentWindowIndex = 0;
            state.selectedSocialMedia = "";
            state.selectedClientId = "";
            state.campaignName = "";
            state.addedAccounts = [];
            saveStateToLocalStorage(state);
        },
        setCurrentWindowIndex: (state, action) => {
            state.currentWindowIndex = action.payload;
            saveStateToLocalStorage(state);
        },
        setSelectSocialMedia: (state, action) => {
            state.selectedSocialMedia = action.payload;
            saveStateToLocalStorage(state);
        },
        setSelectedClientId: (state, action) => {
            state.selectedClientId = action.payload;
            saveStateToLocalStorage(state);
        },
        setCampaignName: (state, action) => {
            state.campaignName = action.payload;
            saveStateToLocalStorage(state);
        },
        addAccount: (state, action) => {
            state.addedAccounts.push(action.payload);
            saveStateToLocalStorage(state);
        },
        removeAccount: (state, action) => {
            state.addedAccounts = state.addedAccounts.filter((account) =>
                account.instagramUsername !== action.payload.instagramUsername ||
                account.influencerId !== action.payload.influencerId
            );
            saveStateToLocalStorage(state);
        },
        setIsAccountInfoOpen: (state, action) => {
            state.isAccountInfoOpen.isOpen = action.payload.isOpen;
            state.isAccountInfoOpen.account = action.payload.account;
            saveStateToLocalStorage(state);
        },            
        setIsAddInfluencerToCampaignModalOpen: (state, action) => {
            state.isAddInfluencerToCampaignModalOpen = action.payload;
            saveStateToLocalStorage(state);
        },
        addContentToCampaign: (state, action) => {
            state.campaignContent.push(action.payload);
            saveStateToLocalStorage(state);
        },
        setContentFieldValue: (state, action) => {
            const content = state.campaignContent.find(content => content._id === action.payload.contentId);
            content[action.payload.fieldName] = action.payload.value;
            saveStateToLocalStorage(state);
        },
        removeContentItemFromCampaign: (state, action) => {
            state.campaignContent = state.campaignContent.filter((content) => content._id !== action.payload);
            saveStateToLocalStorage(state);
        },
        setIsSelectCampaignContentItemModalOpen: (state, action) => {
            state.isSelectCampaignContentItemModalOpen = action.payload;
            saveStateToLocalStorage(state);
        },
    },
});

export const {
    clearState,
    setCurrentWindowIndex,
    setSelectSocialMedia,
    setSelectedClientId,
    setCampaignName,
    addAccount,
    removeAccount,
    setIsAccountInfoOpen,
    setIsAddInfluencerToCampaignModalOpen,
    addContentToCampaign,
    setContentFieldValue,
    removeContentItemFromCampaign,
    setIsSelectCampaignContentItemModalOpen,
} = proposalSystemSlice.actions;

export default proposalSystemSlice.reducer;
