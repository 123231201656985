import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import edit from "../../../../../../images/icons/edit.svg";
import TextInput from "../../../../../form/TextInput";
import {setPersonalInfo} from "../../../../../../redux/slice/admin-edit-influencer";
import {formatPhoneNumber} from "../../../../../../utils/validations";
import ModalWindow from "../../../../../ModalWindow";

export const AdminEditInfluencerPersonalDetails = () => {
    const influencer = useSelector((state) => state.adminEditInfluencer);
    const dispatch = useDispatch();

    const [isOpenPersonal, setIsOpenPersonal] = useState(false);
    const [errorPersonal, setErrorPersonal] = useState({
        firstName: false,
        email: false,
        phone: false,
    });

    return (
        <div className="account-influencer-details-thoomb">
            <div className="account-influencer-details-wrapper">
                <div className="account-influencer-details-wrapper-header">
                    <p className="account-influencer-details-wrapper-header-title">
                        Personal Details
                    </p>

                    <button
                        className="account-influencer-details-wrapper-header-edit"
                        onClick={() => setIsOpenPersonal(true)}
                    >
                        <img
                            className="account-influencer-details-wrapper-header-edit-icon"
                            src={edit}
                        />
                    </button>
                </div>

                <div className="account-influencer-details-wrapper-content">
                    <div className="account-influencer-details-wrapper-content-item">
                        <p className="account-influencer-details-wrapper-content-title">
                            First name
                        </p>
                        <p className="account-influencer-details-wrapper-content-value">
                            {influencer.firstName ? influencer.firstName : "No Data"}
                        </p>
                    </div>
                    <div className="account-influencer-details-wrapper-content-item">
                        <p className="account-influencer-details-wrapper-content-title">
                            Email
                        </p>
                        <p className="account-influencer-details-wrapper-content-value">
                            {influencer.email ? influencer.email : "No Data"}
                        </p>
                    </div>
                    <div className="account-influencer-details-wrapper-content-item">
                        <p className="account-influencer-details-wrapper-content-title">
                            Phone
                        </p>
                        <p className="account-influencer-details-wrapper-content-value">
                            {influencer.phone ? influencer.phone : "No Data"}
                        </p>
                    </div>
                </div>
            </div>
            <ModalWindow
                header="Personal Details"
                isOpen={isOpenPersonal}
                setClose={setIsOpenPersonal}
            >
                <div className="account-influencer-details-form">
                    <TextInput
                        title="First name"
                        placeholder="John Doe"
                        style={{marginTop: "80px"}}
                        value={influencer.firstName}
                        setValue={(value) =>
                            dispatch(setPersonalInfo({key: 'firstName', value}))
                        }
                        error={errorPersonal.firstName}
                        onFocus={() =>
                            setErrorPersonal({...errorPersonal, firstName: false})
                        }
                    />
                    <TextInput
                        title="Email"
                        placeholder="User_email@gmail.com"
                        style={{marginTop: "50px"}}
                        value={influencer.email}
                        setValue={(value) =>
                            dispatch(setPersonalInfo({key: 'email', value}))
                        }
                        error={errorPersonal.email}
                        onFocus={() =>
                            setErrorPersonal({...errorPersonal, email: false})
                        }
                    />
                    <TextInput
                        title="Phone"
                        placeholder="+1 234 567 89 00"
                        style={{marginTop: "50px"}}
                        value={influencer.phone}
                        setValue={(value) =>
                            dispatch(setPersonalInfo({key: 'phone', value: formatPhoneNumber(value)}))
                        }
                        error={errorPersonal.phone}
                        onFocus={() =>
                            setErrorPersonal({...errorPersonal, phone: false})
                        }
                    />
                </div>
            </ModalWindow>
        </div>
    )
}
