import React from 'react'
import TitleSection from "../../../../TitleSection";
import plusIcon from "../../../../../images/icons/plus 2.svg";
import {CampaignItem} from "./components/form/CampaignItem";
import {useNavigate} from "react-router-dom";
import backBtn from "../../../../../images/icons/arrow.svg";
import {useDispatch} from "react-redux";
import {clearState} from "../../../../../redux/slice/proposal-system";

export const AdminManageProposalSystem = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const list = [
        {
            id: 1,
            campaignName: "Campaign 1",
            socialMedia: 'instagram',
            status: "Active",
            date: "2021-09-01",
            createdBy: "client",
            clientName: "Client 1",
            totalFollowers: 300000,
            price: 1000,
        },
        {
            id: 2,
            campaignName: "Campaign 2",
            socialMedia: 'tiktok',
            status: "Active",
            date: "2021-09-01",
            createdBy: "admin",
            clientName: "Client 1",
            totalFollowers: 94358,
            price: 1000,
        },
        {
            id: 3,
            campaignName: "Campaign 3",
            socialMedia: 'youtube',
            status: "Active",
            date: "2021-09-01",
            createdBy: "client",
            clientName: "Client 1",
            totalFollowers: 754657,
            price: 1000,
        }
    ]

    const handleBackButton = () => {
        dispatch(clearState());
        navigate('/admin/home');
    };
    
    return (
        <section className="proposal-system-page">
            <div className="admin-title-section">
                <button onClick={() => handleBackButton()}>
                    <img src={backBtn} style={{transform: "rotate(180deg)"}}/>
                </button>
                <TitleSection title='Proposal' span='System'/>
            </div>

            <div className="create-new-campaign-button"
                 onClick={() => navigate("/admin/proposal-system/create-campaign")}>
                <img src={plusIcon} alt="Create new campaign"/>
                <p>CREATE NEW CAMPAIGN</p>
            </div>

            <div className="horizontal-line"></div>

            <div className="proposal-system-campaigns-list-container">
                {list.map((item, index) => {
                    return (<CampaignItem campaign={item}/>)
                })}
            </div>
        </section>
    )
}
