import React, {useEffect, useState} from "react";
import TitleSection from "../../../../TitleSection";
import FormContainer from "../../../../form/FormContainer";
import UseVerify from "../../../../../hooks/useVerify";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {getSocialMediaIcon} from "../../../../../utils/typeOfSocialAccounts";
import arrow from "../../../../../images/icons/arrow.svg";

function formatDate(inputDate) {
    const date = new Date(inputDate);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

    return formattedDate;
}

const AccountInfluencerOngoingPromos = () => {
    const navigation = useNavigate();
    const [data, setData] = useState([]);
    const [influencerId, setInfluencerId] = useState("");
    
    const getData = async () => {
        try {
            const {dataFetch} = await UseVerify();
            setInfluencerId(dataFetch._id);
            const result = await axios(
                `${process.env.REACT_APP_SERVER}/promos/get-ongoing-promos?influencerId=${dataFetch._id}`
            );
            if (result.data.code === 200) {
                setData(result.data.promos);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    function getBackgroundColor(statusPromo) {
        switch (statusPromo) {
            case "wait":
                return "#FF7A09";
            case "estimate":
                return "#FF3509";
            case "work":
                return "#3330E4";
            case "po waiting":
                return "#17A937";
            default:
                return "#3330E41A";
        }
    }

    const returnStatus = (statusPromo) => {
        if (statusPromo === "wait") {
            return "pending";
        } else if (statusPromo === "work") {
            return "distributing";
        } else if (statusPromo === "estimate") {
            return "estimating";
        } else if (statusPromo === "po waiting") {
            return "po waiting";
        } else {
            return "confirmed";
        }
    };

    return (
        <section className="account-client-past-promos">
            <div className="container-form">
                <div className="account-client-past-promos-block" style={{position: "relative"}}>
                    <div className="account-client-title-section">
                        <TitleSection title="MY" span="account"/>
                        <button
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: 50,
                                height: 50,
                                cursor: "pointer",
                                display: window.innerWidth < 600 ? "none" : "block",
                            }}
                            onClick={() => {
                                navigation("/account/influencer");
                            }}
                        >
                            <img src={arrow} style={{transform: "rotate(180deg)"}}/>
                        </button>
                    </div>

                    <p className="account-client-past-promos-second">Ongoing promos</p>

                    <FormContainer style={{marginTop: "70px"}}>
                        <div className="account-client-past-promos-form">
                            <ul className="account-client-past-promos-form-list">
                                {data.map((item, index) => (
                                    <li
                                        className="account-client-past-promos-form-item"
                                        key={item._id}
                                    >
                                        {/*<button*/}
                                        {/*  className="account-client-past-promos-form-item-button"*/}
                                        {/*  onClick={() =>*/}
                                        {/*    navigation(*/}
                                        {/*      `/account/influencer/ongoing-promos/${item.promoId}/${item.instagramUsername}`*/}
                                        {/*    )*/}
                                        {/*  }*/}
                                        {/*>*/}
                                        {/*  <div className="account-client-past-promos-form-image">*/}
                                        {/*    <p className="account-client-past-promos-form-image-text">*/}
                                        {/*      {item.client}*/}
                                        {/*    </p>*/}
                                        {/*  </div>*/}
                                        {/*  <p className="account-client-past-promos-form-text">*/}
                                        {/*    Promo {index + 1}*/}
                                        {/*  </p>*/}
                                        {/*</button>*/}
                                        <div>
                                            <button
                                                onClick={() => {
                                                    const encodedUsername = encodeURIComponent(item.instagramUsername);
                                                    navigation(`/account/influencer/ongoing-promos/${influencerId}/${item.promoId}/${encodedUsername}`);
                                                }}
                                                className="account-client-past-promos-form-item-button">
                                                <div
                                                    className="account-client-past-promos-form-item-button-inner-content">
                                                    <img src={getSocialMediaIcon(item?.socialMedia)} alt={"inst"}/>
                                                    <p>{item?.campaignName?.length > 10 ? `${item.campaignName.slice(0, 10)}...` : item.campaignName}</p>
                                                </div>
                                                <span
                                                    style={{background: getBackgroundColor(item.statusPromo)}}>{returnStatus(item.statusPromo)}</span>
                                            </button>
                                        </div>
                                        <p style={{
                                            fontFamily: "Geometria",
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            color: "#00000080",
                                        }}>{formatDate(item.createdAt)}</p>
                                        <p className="account-client-past-promos-form-text">
                                            Promo {index + 1}
                                        </p>
                                    </li>
                                ))}
                            </ul>

                            {/* {data.length > 20 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "45px",
                  }}
                >
                  <AltButton text="See more" />
                </div>
              )} */}
                        </div>
                    </FormContainer>
                </div>
            </div>
        </section>
    );
};

export default AccountInfluencerOngoingPromos;
