import React from 'react'
import {getSocialMediaIcon} from "../../../../../../../utils/typeOfSocialAccounts";
import editIcon from "../../../../../../../images/icons/edit 1.svg";
import sendMessage from "../../../../../../../images/icons/mail (1) 1.svg";

export const CampaignItem = ({campaign}) => {
    return (
        <div className="campaign">
            <div className="campaign-icon-name-block">
                <img src={getSocialMediaIcon(campaign.socialMedia)} alt="N/A"/>
                <p>{campaign.campaignName}</p>
            </div>

            <div className="campaign-info-item">
                <span>Client Name:</span>
                <p>{campaign.clientName}</p>
            </div>

            <div className="campaign-info-item">
                <span>Followers:</span>
                <p>{campaign.totalFollowers}</p>
            </div>

            <div className="campaign-info-item">
                <span>Price:</span>
                <p>{campaign.price}</p>
            </div>

            <div className="campaign-info-item">
                <span>Date:</span>
                <p>{campaign.date}</p>
            </div>

            <div className="manage-buttons-block">
                <button>
                    <img src={editIcon} alt="edit"/>
                </button>
                <button>
                    <img src={sendMessage} alt="sendMessage"/>
                </button>
            </div>
        </div>
    )
}
