import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import backBtn from "../../../../../../images/icons/arrow.svg";
import TitleSection from "../../../../../TitleSection";
import {
    setSelectedAccount,
    setCurrentWindow,
    setSelectedAccountClear,
    deleteSocialMediaAccount,
    updateAccount
} from "../../../../../../redux/slice/admin-edit-influencer";
import FormContainer from "../../../../../form/FormContainer";
import TextInput from "../../../../../form/TextInput";
import InputFile from "../../../../../form/InputFile";
import {genres} from "../../../../../../utils/genresList";
import SearchCountry from "../../../../../form/SearchCountry/SearchCountry";
import {categories} from "../../../../../../utils/categoriesList";
import SelectCurrency from "../../../../../form/SelectCurrency/selectCurrency";
import StandardButton from "../../../../../form/StandardButton";
import axios from "axios";

export const AdminEditInfluencerEditPage = () => {
    const selectedAccount = useSelector((state) => state.adminEditInfluencer.selectedAccount);
    const dispatch = useDispatch();

    const [imageUrl, setImageUrl] = useState(null);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
    const [isErrorAfterSubmit, setIsErrorAfterSubmit] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState("€");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const genresSet = new Set();
        const categoriesSet = new Set();

        if (selectedAccount.logo) {
            setImageUrl(selectedAccount.logo);
        }

        if (selectedGenres[0] === undefined) setSelectedGenres([]);

        if (selectedAccount.musicStyle !== undefined && selectedAccount.musicStyle) {
            if (selectedAccount.musicSubStyles.length > 0) {
                for (let i = 0; i < selectedAccount.musicSubStyles.length; i++) {
                    if (selectedAccount.musicSubStyles[i] === "Melodic, Minimal") {
                        genresSet.add("Techno (Melodic, Minimal)");
                    }
                    if (selectedAccount.musicSubStyles[i] === "Hard, Peak") {
                        genresSet.add("Techno (Hard, Peak)");
                    }
                    if (selectedAccount.musicSubStyles[i] === "Tech House") {
                        genresSet.add("House (Tech House)");
                    }
                    if (selectedAccount.musicSubStyles[i] === "Melodic, Afro") {
                        genresSet.add("House (Melodic, Afro)");
                    }
                }
            } else {
                genresSet.add(selectedAccount.musicStyle);
            }

            for (let i = 0; i < selectedAccount.musicStyleOther.length; i++) {
                if (selectedAccount.musicStyleOther[i] !== "House") {
                    genresSet.add(selectedAccount.musicStyleOther[i]);
                }
            }

            setSelectedGenres(Array.from(genresSet));
        }

        if (selectedAccount.categories !== undefined && selectedAccount.categories.length > 0) {
            for (let i = 0; i < selectedAccount.categories.length; i++) {
                categoriesSet.add(selectedAccount.categories[i]);
            }
        }

        setSelectedCategories(Array.from(categoriesSet));

        if (selectedAccount.countries && selectedAccount.countries.length > 0) {
            setSelectedCountries(selectedAccount.countries);
        }
    }, []);

    useEffect(() => {
        if ((selectedGenres.length > 0 || selectedCategories.length > 0) && selectedCountries.length > 0 && selectedAccount.price && selectedCurrency && selectedAccount.logo && selectedAccount.instagramLink && selectedAccount.instagramUsername && selectedAccount.followersNumber) {
            setIsAllFieldsFilled(true);
        }
    }, [selectedAccount, selectedCurrency, selectedGenres, selectedCountries, selectedCategories]);

    const prevPage = () => {
        dispatch(setSelectedAccountClear());
        dispatch(setCurrentWindow(0));
    }

    const returnPriceInput = () => {
        switch (selectedAccount.socialMedia) {
            case 'Instagram':
                return 'Price for 1 Post & Story, include your currency*';
            case 'TikTok':
                return 'Price for 1 TikTok Post & Story, include your currency*';
            case 'Facebook':
                return 'Price for 1 Facebook Post & Story, include your currency*';
            case 'Spotify':
                return 'Price for 1 Spotify Feedback+ include your currency*';
            case 'SoundCloud':
                return 'Price for 1 SoundCloud Repost (10 days lenght minimum), include your currency*';
            case 'YouTube':
                return 'Price for 1 YouTube Post, include your currency*';
            case 'Press':
                return 'Price for 1 Article, include your currency*';
            default:
                return '';
        }
    }

    const returnAccountNameInput = () => {
        switch (selectedAccount.socialMedia) {
            case 'Instagram':
                return 'Instagram account name';
            case 'TikTok':
                return 'TikTok account name';
            case 'facebook':
                return 'Facebook account name';
            case 'Spotify':
                return 'Spotify playlist name';
            case 'SoundCloud':
                return 'SoundCloud account name';
            case 'YouTube':
                return 'YouTube account name';
            case 'Press':
                return 'Brand account name';
            default:
                return '';
        }
    }

    const returnLinkInput = () => {
        switch (selectedAccount.socialMedia) {
            case 'Instagram':
                return 'Instagram link';
            case 'TikTok':
                return 'TikTok link';
            case 'facebook':
                return 'Facebook link';
            case 'Spotify':
                return 'Spotify playlist link';
            case 'SoundCloud':
                return 'SoundCloud link';
            case 'YouTube':
                return 'YouTube link';
            case 'Press':
                return 'Website link';
            default:
                return '';
        }
    }

    const handleFieldChangeAccountDetails = (field, value) => {
        dispatch(setSelectedAccount({...selectedAccount, [field]: value}));
    }

    const handleAvatarChange = (file) => {
        if (file && file.type && file.type.startsWith("image/")) {
            dispatch(setSelectedAccount({...selectedAccount, logo: file}));

            const reader = new FileReader();
            reader.onload = (e) => {
                setImageUrl(e.target.result);
            };
            reader.readAsDataURL(file);
        } else {
            dispatch(setSelectedAccount({...selectedAccount, logo: ""}));
            setImageUrl(null);
        }
    };

    const handleGenreSelect = (genre) => {
        if (selectedGenres.includes(genre)) {
            setSelectedGenres(selectedGenres.filter((selectedGenre) => selectedGenre !== genre));
        } else {
            setSelectedGenres([...selectedGenres, genre]);
        }
    }

    const handleCountryChange = (index, field, value) => {
        if (field === 'percentage' && !/^[0-9]*\.?[0-9]*$/.test(value)) return;

        const newCountries = [...selectedCountries];
        newCountries[index] = {...newCountries[index], [field]: value};
        setSelectedCountries(newCountries);
    };

    const handleCategorySelect = (category) => {
        if (selectedCategories.includes(category)) {
            setSelectedCategories(selectedCategories.filter((selectedCategory) => selectedCategory !== category));
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    }

    const filterMusicGenres = () => {
        let musicStyle;
        let musicSubStyles = [];
        let musicStyleOther = [];

        const extractSubGenres = (genre) => {
            const match = genre.match(/\(([^)]+)\)/);
            return match ? [match[1].trim()] : [];
        };

        if (selectedGenres.includes("Techno (Melodic, Minimal)") || selectedGenres.includes("Techno (Hard, Peak)")) {
            musicStyle = "Techno";
            musicSubStyles = selectedGenres.flatMap(genre => extractSubGenres(genre));
        }

        if (selectedGenres.includes("House (Tech House)") || selectedGenres.includes("House (Melodic, Afro)")) {
            musicStyle = musicStyle === "Techno" ? musicStyle : "House";
            musicSubStyles = selectedGenres.flatMap(genre => extractSubGenres(genre));
        }

        if (musicStyle === "Techno" || musicStyle === "House") {
            selectedGenres.forEach(genre => {
                if (!musicStyleOther.includes(genre) && !genre.includes(musicStyle)) {
                    if (genre.includes("House") && !musicStyleOther.includes("House")) {
                        musicStyleOther.push("House");
                    } else if (!genre.includes("House")) {
                        musicStyleOther.push(genre);
                    }
                }
            });
        }

        if (!selectedGenres.includes("Techno (Melodic, Minimal)") && !selectedGenres.includes("Techno (Hard, Peak)") && !selectedGenres.includes("House (Tech House)") && !selectedGenres.includes("House (Melodic, Afro)")) {
            musicStyle = selectedGenres[0];
            if (selectedGenres.length > 1) {
                musicStyleOther = selectedGenres.slice(1);
            }
        }

        return {musicStyle, musicSubStyles, musicStyleOther};
    };

    const convertToEuro = () => {
        let newPrice = selectedAccount.price;
        
        switch (selectedCurrency) {
            case "$":
                newPrice = Math.floor(selectedAccount.price * 0.96);
                return newPrice;
            case "£":
                newPrice = Math.floor(selectedAccount.price * 1.25);
                return newPrice;
            default:
                return newPrice;
        }
    }

    const addAccountToAttached = async () => {
        const {musicStyle, musicSubStyles, musicStyleOther} = filterMusicGenres();

        let logoUrl = selectedAccount.logo ? selectedAccount.logo : null;

        if (imageUrl && imageUrl !== selectedAccount.logo) {
            const formData = new FormData();
            formData.append('file', selectedAccount.logo);
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER}/promos/uploadScreenshot`,
                formData,
                {headers: {"Content-Type": "multipart/form-data"}}
            );
            if (response.data.code === 200) {
                logoUrl = response.data.data;
            }
        }

        const newPrice = convertToEuro();

        dispatch(updateAccount({
            socialMedia: selectedAccount.socialMedia.toLowerCase(),
            account: {
                ...selectedAccount,
                countries: selectedCountries,
                musicStyle: musicStyle,
                musicSubStyles: musicSubStyles || [],
                musicStyleOther: musicStyleOther || [],
                logo: logoUrl,
                categories: selectedCategories || [],
                isHidden: false,
                isVerified: true,
                price: newPrice.toString(),
                publicPrice: (parseInt(newPrice) * 2).toString(),
            }
        }));
        dispatch(setSelectedAccountClear());
        dispatch(setCurrentWindow(0));
    };

    const saveAccount = async () => {
        const {musicStyle, musicSubStyles, musicStyleOther} = filterMusicGenres();
        let logoUrl = null;

        if (imageUrl && imageUrl !== selectedAccount.logo) {
            try {
                const formData = new FormData();
                formData.append('file', selectedAccount.logo);
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER}/promos/uploadScreenshot`,
                    formData,
                    {headers: {"Content-Type": "multipart/form-data"}}
                );

                if (response.data.code === 200) {
                    logoUrl = response.data.data;
                }
            } catch (error) {
                console.log("Error uploading logo");
            }
        }

        let newPrice = 0;
        if (selectedAccount.price) {
            newPrice = convertToEuro()
        }

        dispatch(updateAccount({
            socialMedia: selectedAccount.socialMedia.toLowerCase(),
            account: {
                ...selectedAccount,
                logo: logoUrl ? logoUrl : selectedAccount.logo,
                countries: selectedCountries ? selectedCountries : [],
                categories: selectedCategories ? selectedCategories : [],
                musicStyle: musicStyle,
                musicSubStyles: musicSubStyles,
                musicStyleOther: musicStyleOther,
                instagramLink: selectedAccount.instagramLink ? selectedAccount.instagramLink : "",
                instagramUsername: selectedAccount.instagramUsername ? selectedAccount.instagramUsername : "",
                followersNumber: selectedAccount.followersNumber ? selectedAccount.followersNumber : 0,
                price: selectedAccount.price ? newPrice.toString() : "",
                currency: selectedCurrency ? selectedCurrency : "",
                isHidden: false,
                isVerified: true,
                publicPrice: (parseInt(newPrice) * 2).toString(),
            }
        }));
        dispatch(setSelectedAccountClear());
        dispatch(setCurrentWindow(0));
    };

    return (
        <section className="signup-influencer">
            <div className="admin-title-section">
                <button style={{display: window.innerWidth < 900 ? "none" : "block"}} onClick={() => prevPage()}>
                    <img src={backBtn} style={{transform: "rotate(180deg)"}}/>
                </button>
                <div className="signup-influencer-title">
                    <TitleSection title='Add' span={selectedAccount.socialMedia + ' account details'}/>
                </div>
            </div>
            <div className="container-form">
                <div className='signup-influencer-block'>
                    <FormContainer style={{margin: '30px auto', width: '90%'}}>
                        <div className="signup-influencer-social-media-form">
                            <div>
                                <TextInput title={returnAccountNameInput()}
                                           placeholder={`Enter ${selectedAccount.socialMedia} account name`}
                                           style={{maxWidth: '665px', margin: '30px auto 60px auto'}}
                                           value={selectedAccount.instagramUsername}
                                           setValue={(value) => handleFieldChangeAccountDetails('instagramUsername', value)}/>
                                <TextInput title={returnLinkInput()}
                                           placeholder={`Enter ${selectedAccount.socialMedia} link`}
                                           style={{maxWidth: '665px', margin: '0 auto 60px auto'}}
                                           value={selectedAccount.instagramLink}
                                           setValue={(value) => handleFieldChangeAccountDetails('instagramLink', value)}/>
                                <TextInput
                                    title={selectedAccount.socialMedia !== "Press" ? "Followers Number" : "Average Monthly Traffic"}
                                    placeholder={selectedAccount.socialMedia !== "Press" ? "Enter followers number" : "Enter average monthly traffic number"}
                                    style={{maxWidth: '665px', margin: '0 auto 60px auto'}}
                                    value={selectedAccount.followersNumber}
                                    setValue={(value) => handleFieldChangeAccountDetails('followersNumber', value)}/>
                                <InputFile
                                    title="Logo"
                                    placeholder="Attach the logo for your brand here"
                                    value={selectedAccount.logo}
                                    style={{margin: '-25px auto 60px auto', maxWidth: '665px'}}
                                    setValue={(value) => handleAvatarChange(value)}
                                    className={"instagram-select-item-file"}
                                    setUploadProgress={() => {
                                    }}
                                />
                                {imageUrl && (
                                    <div>
                                        <div className="avatar-container">
                                            <img src={imageUrl} alt="Uploaded Logo"/>
                                        </div>
                                        <div className="cancel-avatar-btn">
                                            <button onClick={() => handleAvatarChange(null)}>CANCEL</button>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="genres-countries">
                                <div className="block"
                                     style={{width: selectedAccount.socialMedia === "Spotify" && '100%'}}>
                                    <p id='title'>MUSIC GENRES</p>
                                    <p>Select <b>ALL</b> the applicable</p>
                                    {genres.map((genre, index) => (
                                        <div key={index} className="checkbox">
                                            <input type="checkbox"
                                                   id={genre}
                                                   name={genre}
                                                   checked={selectedGenres.includes(genre)}
                                                   onChange={() => handleGenreSelect(genre)}
                                            />
                                            <label htmlFor={genre}>{genre}</label>
                                        </div>
                                    ))}
                                    {selectedAccount.socialMedia === "Spotify" && (
                                        <div>
                                            <div className="checkbox">
                                                <input type="checkbox"
                                                       id={"Dance Pop"}
                                                       name={"Dance Pop"}
                                                       checked={selectedGenres.includes("Dance Pop")}
                                                       onChange={() => handleGenreSelect("Dance Pop")}
                                                />
                                                <label htmlFor="Dance Pop">Dance Pop</label>
                                            </div>
                                            <div className="checkbox">
                                                <input type="checkbox"
                                                       id={"Garage"}
                                                       name={"Garage"}
                                                       checked={selectedGenres.includes("Garage")}
                                                       onChange={() => handleGenreSelect("Garage")}
                                                />
                                                <label htmlFor="Garage">Garage</label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {selectedAccount.socialMedia !== "Spotify" && (
                                    <div className="block">
                                        <p id='title'>TOP LOCATIONS</p>
                                        <p>Enter the top 5 Countries, and their percentage</p>
                                        <div className='countries-container'>
                                            <div className='country'>
                                                <span>#1</span>
                                                <TextInput style={{
                                                    padding: '13px 10px',
                                                    width: window.innerWidth < 768 ? '15%' : '30%'
                                                }}
                                                           silverColor={true}
                                                           placeholder='19.4%'
                                                           value={selectedCountries[0]?.percentage}
                                                           setValue={(value) => handleCountryChange(0, "percentage", value)}/>
                                                <div style={{marginTop: 10}}>
                                                    <SearchCountry indexOfSelectingCountry={0}
                                                                   handleCountryChange={handleCountryChange}
                                                                   selectedCountries={selectedCountries}/>
                                                </div>
                                            </div>
                                            <div className='country'>
                                                <span>#2</span>
                                                <TextInput style={{
                                                    padding: '13px 10px',
                                                    width: window.innerWidth < 768 ? '15%' : '30%'
                                                }}
                                                           silverColor={true}
                                                           placeholder='9.4%'
                                                           value={selectedCountries[1]?.percentage}
                                                           setValue={(value) => handleCountryChange(1, "percentage", value)}/>
                                                <div style={{marginTop: 10}}>
                                                    <SearchCountry indexOfSelectingCountry={1}
                                                                   handleCountryChange={handleCountryChange}
                                                                   selectedCountries={selectedCountries}/>
                                                </div>
                                            </div>
                                            <div className='country'>
                                                <span>#3</span>
                                                <TextInput style={{
                                                    padding: '13px 10px',
                                                    width: window.innerWidth < 768 ? '15%' : '30%'
                                                }}
                                                           silverColor={true}
                                                           value={selectedCountries[2]?.percentage}
                                                           setValue={(value) => handleCountryChange(2, "percentage", value)}
                                                           placeholder='4.4%'/>
                                                <div style={{marginTop: 10}}>
                                                    <SearchCountry indexOfSelectingCountry={2}
                                                                   handleCountryChange={handleCountryChange}
                                                                   selectedCountries={selectedCountries}/>
                                                </div>
                                            </div>
                                            <div className='country'>
                                                <span>#4</span>
                                                <TextInput style={{
                                                    padding: '13px 10px',
                                                    width: window.innerWidth < 768 ? '15%' : '30%'
                                                }}
                                                           silverColor={true}
                                                           value={selectedCountries[3]?.percentage}
                                                           setValue={(value) => handleCountryChange(3, "percentage", value)}
                                                           placeholder='3.4%'/>
                                                <div style={{marginTop: 10}}>
                                                    <SearchCountry indexOfSelectingCountry={3}
                                                                   handleCountryChange={handleCountryChange}
                                                                   selectedCountries={selectedCountries}/>
                                                </div>
                                            </div>
                                            <div className='country'>
                                                <span>#5</span>
                                                <TextInput style={{
                                                    padding: '13px 10px',
                                                    width: window.innerWidth < 768 ? '15%' : '30%'
                                                }}
                                                           silverColor={true}
                                                           value={selectedCountries[4]?.percentage}
                                                           setValue={(value) => handleCountryChange(4, "percentage", value)}
                                                           placeholder='1.4%'/>
                                                <div style={{marginTop: 10}}>
                                                    <SearchCountry indexOfSelectingCountry={4}
                                                                   handleCountryChange={handleCountryChange}
                                                                   selectedCountries={selectedCountries}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {(selectedAccount.socialMedia === 'Instagram' || selectedAccount.socialMedia === 'facebook' || selectedAccount.socialMedia === 'YouTube') && (
                                <div className="categories">
                                    <div className="block">
                                        <p id='title'>KEY TOPICS</p>
                                        <p>Select <b>THIS</b> if the main core theme of the page</p>
                                        {categories.map((category, index) => (
                                            <div key={index} className="checkbox">
                                                <input type="checkbox"
                                                       id={category}
                                                       name={category}
                                                       checked={selectedCategories.includes(category)}
                                                       onChange={() => handleCategorySelect(category)}
                                                />
                                                <label htmlFor={category}>{category}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            <div className="price-input-container">
                                <p id='price-input-title'>{returnPriceInput()}</p>
                                <div className="price-input-container-block">
                                    <div id="price-input-field">
                                        <input type="text" placeholder='50'
                                               value={selectedAccount.price}
                                               onChange={(e) => handleFieldChangeAccountDetails('price', e.target.value)}/>
                                    </div>
                                    <div>
                                        <SelectCurrency selectedCurrency={selectedCurrency}
                                                        setSelectedCurrency={setSelectedCurrency}/>
                                    </div>
                                </div>
                            </div>

                            {isAllFieldsFilled ? (
                                <div className="save-account-button">
                                    <StandardButton text="Add Account" onClick={() => addAccountToAttached()}/>
                                </div>
                            ) : (
                                <div className="save-account-button">
                                    <StandardButton text="Save Account" onClick={() => saveAccount()}/>
                                </div>
                            )}

                            <div className="delete-account-button">
                                <StandardButton isBlue={true} text="Delete Account" onClick={() => {
                                    dispatch(deleteSocialMediaAccount({
                                        socialMedia: selectedAccount.socialMedia.toLowerCase(),
                                        _id: selectedAccount._id,
                                    }));
                                    dispatch(setCurrentWindow(0));
                                }}/>
                            </div>

                            {isErrorAfterSubmit && (
                                <div className="error-message">
                                    <p>There was an error adding the account. Please try again.</p>
                                </div>
                            )}
                        </div>
                    </FormContainer>
                </div>
            </div>
        </section>
    )
}
