import React from "react";
import Header from "../../../components/Header";
import Background from "../../../components/Background";
import AdminInfluencersHistoryPage from "../../../components/layout/account/admin/AdminInfluencersHistory/AdminInfluencersHistoryPage";

const AccountAdminInfluencersHistory = () => {
    return (
        <>
            <Header path="Admin/Influencers/History"/>
            <AdminInfluencersHistoryPage/>
            <Background />
        </>
    );
};

export default AccountAdminInfluencersHistory;
