import React, {useEffect, useState} from 'react'
import {getSocialMediaIcon} from "../../../../../../utils/typeOfSocialAccounts";
import edit from "../../../../../../images/icons/edit.svg";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentWindow, setSelectedAccount} from "../../../../../../redux/slice/admin-edit-influencer";

export const AdminEditInfluencerApprovedAccounts = () => {
    const influencer = useSelector((state) => state.adminEditInfluencer);
    const dispatch = useDispatch();

    const [socialMediaAccounts, setSocialMediaAccounts] = useState({
        instagram: [],
        tiktok: [],
        facebook: [],
        spotify: [],
        soundcloud: [],
        youtube: [],
        press: [],
    });

    useEffect(() => {
        setSocialMediaAccounts({
            instagram: influencer.instagram.filter((acc) => !acc.isDeleted && !acc.isNew) || [],
            tiktok: influencer?.tiktok.filter((acc) => !acc.isDeleted && !acc.isNew) || [],
            facebook: influencer?.facebook.filter((acc) => !acc.isDeleted && !acc.isNew) || [],
            spotify: influencer?.spotify.filter((acc) => !acc.isDeleted && !acc.isNew) || [],
            soundcloud: influencer?.soundcloud.filter((acc) => !acc.isDeleted && !acc.isNew) || [],
            youtube: influencer?.youtube.filter((acc) => !acc.isDeleted && !acc.isNew) || [],
            press: influencer?.press.filter((acc) => !acc.isDeleted && !acc.isNew) || [],
        });
    }, []);

    return (
        <div className="account-influencer-details-wrapper">
            <div className="account-influencer-details-wrapper-header">
                <p className="account-influencer-details-wrapper-header-title">
                    APPROVED BRAND ACCOUNTS
                </p>
            </div>
            <div className="account-influencer-details-wrapper-content">
                <div className="account-influencer-details-approved-accounts-list">
                    {Object.entries(socialMediaAccounts)
                        .flatMap(([platform, accounts]) =>
                            accounts.map(account => ({
                                ...account,
                                typeOfSocialMedia: platform,
                            }))
                        )
                        .map((account, index) => (
                            <div key={index} className="account-item" style={{justifyContent: 'normal', gap: 23}}>
                                <img src={getSocialMediaIcon(account.typeOfSocialMedia)}
                                     alt='Logo'/>
                                <div>
                                    <p>{account.instagramUsername || 'No username available'}</p>
                                    {!account.isVerified && <div className="label-not-verified">
                                        <span>Under Approval</span>
                                    </div>}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}
